import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./pages/Main";

export default function AppRoute() {
    // https://tracker.ism.app.br?transactionID={apid}&valor={payment}&moeda={currency}&influ={subid1}
        //&status={status}&campanha={offer}&subID={subID}&=dataDoClick={clickDate}
    // http://localhost:3000/?transactionID=1002&valor=30&moeda=real&influ=1006&status=ativo&campanha=12345&=dataDoClick=25/10/2023
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Main />}/>  
            </Routes>
        </BrowserRouter>
    )
}

