import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore/lite';
import { getStorage } from "firebase/storage"
//
import { getAuth } from "firebase/auth"


const firebaseConfig = {
    apiKey: "AIzaSyDcoQtDpmAt0c9XiYzKQdCPA7q_3b0PvS0",
    authDomain: "ism-teste.firebaseapp.com",
    projectId: "ism-teste",
    storageBucket: "ism-teste.appspot.com",
    messagingSenderId: "771313201788",
    appId: "1:771313201788:web:ba9fe3fd827562f6514ff9",
    measurementId: "G-Z3727M88BX"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
//

export const auth = getAuth(app);











