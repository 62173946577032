import { addDoc, collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore/lite";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { db } from "../db/banco";
import { pegarDataHora } from "../utils/functions";
import IInfluenciador from "../types/IInfluenciador";

export default function Main() {
    // Para pegar os parâmetros.
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const transactionID = params.get("transactionID"); // Parâmetro da página
    const valor = params.get("valor"); // Parâmetro da página   
    const moeda = params.get("moeda"); // Parâmetro da página   
    const influ = params.get("influ"); // Parâmetro da página    
    const status = params.get("status"); // Parâmetro da página   
    const campanha = params.get("campanha"); // Parâmetro da página   
    const subID = params.get("subID"); // Parâmetro da página   
    const dataDoClickParametro = params.get("dataDoClick"); // Parâmetro da página    
    // Recebe os influenciadores
    const [influenciadores, setInfluenciadores] = useState<IInfluenciador[]>([]);
    const [nomeDoInfluenciador, setNomeDoInfluenciador] = useState("");

    // banco de dados
    const useCollectionRefPostack = collection(db, "postback");

    useEffect(() => {
        const confereSeJaExiste = async () => {
            const q = query(useCollectionRefPostack, where("transactionID", "==", transactionID));
            const querySnapshot = await getDocs(q);
            const { horaClick, dataClick } = pegarDataHora();

            const useCollectionRefInfluenciador = collection(db, "influenciador");
            const obterPostacks = async () => {
                const dataBD = await getDocs(useCollectionRefInfluenciador);
                const todosInfluenciadores: IInfluenciador[] = dataBD.docs.map(doc => ({ ...doc.data(), id: doc.id }) as IInfluenciador);
                const somenteInfluenciadoresComCodigoActionpay = todosInfluenciadores.filter(peloCodigo => peloCodigo.codigoActionpayInfluenciador);
                console.log(somenteInfluenciadoresComCodigoActionpay);

                if (influ) {
                    const influConvertido = Number(influ);
                    const influenciadorEncontrado = somenteInfluenciadoresComCodigoActionpay.find(peloParametro => peloParametro.codigoActionpayInfluenciador === influConvertido);
                    if (influenciadorEncontrado) {
                        setNomeDoInfluenciador(influenciadorEncontrado.nomeCompleto);
                        return influenciadorEncontrado.nomeCompleto; // Retorna o nome do influenciador
                    }
                }
                return null;
            };

            const nome = await obterPostacks();

            if (!querySnapshot.empty) {
                querySnapshot.forEach(doc => {
                    console.log(doc.id, " => ", doc.data());
                    const influConvertido = Number(influ);
                    const valorConvertido = Number(valor);
                    const postack = {                        
                        valor: valorConvertido,
                        moeda,
                        influ: influConvertido,
                        status,
                        campanha,
                        subID,
                        dataDoClickParametro,
                        horaClick,
                        dataClick,
                        nomeInfluenciador: nome // Use o nome do influenciador retornado
                    };

                    editarLinkPostack(postack, doc.id);
                });

                return;
            }
            if (transactionID && valor) {
                criarNovaTransacao(horaClick, dataClick, nome);
            }
        };

        if (transactionID) {
            confereSeJaExiste();
        }
    }, [transactionID, valor, moeda, influ, status, campanha, dataDoClickParametro, nomeDoInfluenciador]); // Adiciona nomeDoInfluenciador como dependência

    const criarNovaTransacao = (horaClick: string, dataClick: string, nomeInfluenciador: string | null) => {
        const influConvertido = Number(influ);
        const valorConvertido = Number(valor);
        const novaTransacao = {
            transactionID,
            valor: valorConvertido,
            moeda,
            influ: influConvertido,
            status,
            campanha,
            subID,
            dataDoClickParametro,
            horaClick,
            dataClick,
            nomeInfluenciador
        };

        addDoc(useCollectionRefPostack, novaTransacao)
            .then(docRef => {
                console.log("Nova transação criada com ID: ", docRef.id);
            })
            .catch(error => {
                console.error("Erro ao adicionar nova transação: ", error);
            });
    };

    async function editarLinkPostack(novosDados: any, idPostack: string) {
        try {
            const postbackDocRef = doc(db, "postback", idPostack);

            await updateDoc(postbackDocRef, novosDados);
            console.log("Dados atualizados com sucesso");
        } catch (error) {
            console.error("Erro ao atualizar dados", error);
        }
    }

    return (
        <div>
            <p> Servidor postback ISM </p>            
        </div>
    );
}
